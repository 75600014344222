export const Scribble = () =>
{
return(<svg width="178" height="86" viewBox="0 0 178 86" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.16" d="M1 171.304L62.7247 170.711C37.7368 140.596 9.99379 86.9565 98.9251 113.317L77.2746 58.3675C115.374 69.0267 175.049 73.9056 108.95 8.14709L177.871 1.47527" stroke="white"/>
</svg>)
}

export const ScribbleTop = () =>
{
return(<svg width="99" height="68" viewBox="0 0 99 68" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.75">
<path opacity="0.15" d="M13.3398 52.0312L41.0263 53.9696C30.9018 39.5797 20.3824 14.5476 59.3007 29.5375L51.5587 4.13605C68.2546 10.2739 94.8268 14.5912 67.5489 -17.242L98.6777 -17.7716" stroke="white"/>
<path opacity="0.15" d="M1 41.6924L28.6864 43.6307C18.562 29.2409 8.0426 4.20869 46.9609 19.1986L39.2189 -6.20282C55.9147 -0.064983 82.4869 4.25237 55.209 -27.5809L86.3379 -28.1105" stroke="white"/>
</g>
</svg>)
}


export const ScribbleSide = () =>
{
return(<svg width="63" height="109" viewBox="0 0 63 109" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.1" d="M22.7227 1L15.995 25.7139C30.8394 19.1338 55.3883 14.1079 34.8287 46.6447L59.213 44.1992C50.6581 58.21 41.977 81.488 75.6925 62.5136L70.5805 90.787" stroke="white"/>
<path opacity="0.1" d="M8.50781 18.4297L1.54783 43.431C16.6009 36.6917 41.4578 31.4604 20.4607 64.4497L45.1347 61.8255C36.3947 76.0293 27.4685 99.5928 61.6835 80.2169L56.3348 108.803" stroke="white"/>
</svg>
)
}


export const DecorSide = () =>
{
return(<svg width="164" height="14" viewBox="0 0 164 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect opacity="0.17" x="202" y="13" width="152" height="12" rx="6" transform="rotate(-180 202 13)" fill="white"/>
<circle opacity="0.17" cx="31" cy="7" r="5" transform="rotate(-180 31 7)" stroke="white" stroke-width="2"/>
<g opacity="0.17">
<path d="M13.2852 1.34414L1.8627 12.7441" stroke="white" stroke-width="2" stroke-linecap="round"/>
<path d="M12.6841 12.7434L2.41614 1.4202" stroke="white" stroke-width="2" stroke-linecap="round"/>
</g>
</svg>

)
}


export const ServiceOneFaceScribble = () =>{
    return(
        <svg width="546" height="135" viewBox="0 0 546 135" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M545.999 4.09976L-22.8392 134.812L-23.4805 131.483L545.252 0.82373L545.999 4.09976Z" fill="#FF91A3"/>
</svg>
    )
}

export const ScribbleArc = () =>{
    return(
        <svg width="293" height="261" viewBox="0 0 293 261" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1.99551C91.8813 -6.30443 277.315 33.6765 292 260" stroke="#FF5065" stroke-linecap="round"/>
        </svg>
        
    )
}


export const ScribbleArc2 = () =>{
    return(
        <svg width="278" height="252" viewBox="0 0 278 252" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1.96122C87.1967 -6.0503 263.072 32.5414 277 251" stroke="#10BEA1" stroke-linecap="round"/>
        </svg>
        
        
    )
}

export const ScribbleArc3 = () =>{
    return(
        <svg width="287" height="252" viewBox="0 0 287 252" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.47422 2.87286C88.9186 -6.80657 268.315 29.3356 286.347 251.34" stroke="#E758FF" stroke-linecap="round"/>
        </svg>
        
        
    )
}


export const Line = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="824" height="2" viewBox="0 0 824 2" fill="none">
  <path d="M0 1H824" stroke="#B3B3B3" stroke-width="0.5"/>
</svg>
    )
}

export const SmallLine = () =>{
    return(<svg width="80" height="2" viewBox="0 0 80 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 1H80" stroke="#B3B3B3" stroke-width="0.5"/>
    </svg>
    )
}

export const ScribbleAboutPage = () =>{
    return(<svg width="150" height="18" viewBox="0 0 193 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.26" x="384.337" y="23.5" width="290" height="22" rx="11" transform="rotate(-180 384.337 23.5)" stroke="white"/>
    <circle opacity="0.26" cx="57.3369" cy="12.5" r="10.5" transform="rotate(-180 57.3369 12.5)" stroke="white" stroke-width="2"/>
    <g opacity="0.26">
    <path d="M23.3413 1.65964L1.44826 23.5096" stroke="white" stroke-width="2" stroke-linecap="round"/>
    <path d="M22.1891 23.5083L2.50886 1.80546" stroke="white" stroke-width="2" stroke-linecap="round"/>
    </g>
    </svg>
    )
}

export const MobileIcon = () =>{
    return(
        <svg width="19" height="30" viewBox="0 0 19 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 27H13" stroke="white" stroke-linecap="round"/>
<rect x="0.5" y="0.5" width="18" height="29" rx="1.5" stroke="white"/>
</svg>
    )
}

export const Line2 = () =>{
    return(
        <svg width="80" height="2" viewBox="0 0 80 2" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 1H80" stroke="#B3B3B3" stroke-width="0.5"/>
</svg>

    )
}

export const Dots = () =>{
    return(
        <svg width="117" height="5" viewBox="0 0 117 5" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle opacity="0.3" cx="2.5" cy="2.5" r="2" stroke="white"/>
<circle opacity="0.3" cx="25.5" cy="2.5" r="2" stroke="white"/>
<circle opacity="0.3" cx="47.5" cy="2.5" r="2" stroke="white"/>
<circle opacity="0.3" cx="91.5" cy="2.5" r="2" stroke="white"/>
<circle opacity="0.3" cx="69.5" cy="2.5" r="2" stroke="white"/>
<circle opacity="0.3" cx="114.5" cy="2.5" r="2" stroke="white"/>
</svg>

    )
}

export const TeamOrbit = () =>{
    return(<svg width="1800" height="450" viewBox="0 0 1006 541" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.09">
<g style={{mixBlendMode:'screen'}}>
<path d="M714.5 454C715.091 454 715.57 450.194 715.57 445.5C715.57 440.806 715.091 437 714.5 437C713.909 437 713.43 440.806 713.43 445.5C713.43 450.194 713.909 454 714.5 454Z" fill="url(#paint0_radial_0_1)"/>
</g>
<g style={{mixBlendMode:'screen'}}>
<path d="M714.5 446.57C719.194 446.57 723 446.091 723 445.5C723 444.909 719.194 444.43 714.5 444.43C709.806 444.43 706 444.909 706 445.5C706 446.091 709.806 446.57 714.5 446.57Z" fill="url(#paint1_radial_0_1)"/>
</g>
<g style={{mixBlendMode:'screen'}}>
<path d="M719.692 450.692C720.053 450.331 718.021 447.713 715.153 444.845C712.286 441.977 709.668 439.945 709.307 440.307C708.945 440.668 710.977 443.286 713.845 446.154C716.713 449.021 719.331 451.053 719.692 450.692Z" fill="url(#paint2_radial_0_1)"/>
</g>
<g style={{mixBlendMode:'screen'}}>
<path d="M713.846 444.845C716.716 441.975 719.333 439.949 719.694 440.305C720.056 440.661 718.023 443.285 715.155 446.153C712.287 449.021 709.677 451.054 709.309 450.693C708.941 450.331 710.978 447.713 713.846 444.845Z" fill="url(#paint3_radial_0_1)"/>
</g>
<g style={{mixBlendMode:'screen'}}>
<path d="M715.19 445.213C716.445 448.241 717.152 450.823 716.77 450.981C716.389 451.14 715.062 448.814 713.809 445.785C712.557 442.756 711.848 440.175 712.227 440.016C712.607 439.858 713.936 442.186 715.19 445.213Z" fill="url(#paint4_radial_0_1)"/>
</g>
<g style={{mixBlendMode:'screen'}}>
<path d="M714.211 444.807C717.24 443.555 719.823 442.846 719.98 443.227C720.137 443.609 717.812 444.936 714.783 446.19C711.755 447.444 709.167 448.16 709.015 447.77C708.863 447.38 711.193 446.062 714.211 444.807Z" fill="url(#paint5_radial_0_1)"/>
</g>
<g style={{mixBlendMode:'screen'}}>
<path d="M714.74 444.902C717.357 445.986 719.366 447.132 719.231 447.461C719.096 447.791 716.867 447.179 714.245 446.096C711.624 445.012 709.62 443.866 709.757 443.536C709.893 443.207 712.13 443.818 714.74 444.902Z" fill="url(#paint6_radial_0_1)"/>
</g>
<g style={{mixBlendMode:'screen'}}>
<path d="M713.896 445.252C714.98 442.636 716.126 440.627 716.456 440.763C716.785 440.9 716.174 443.127 715.09 445.747C714.006 448.367 712.861 450.373 712.531 450.236C712.2 450.099 712.819 447.869 713.896 445.252Z" fill="url(#paint7_radial_0_1)"/>
</g>
<path style={{mixBlendMode:'screen'}} d="M714.498 447.682C715.702 447.682 716.679 446.705 716.679 445.5C716.679 444.296 715.702 443.319 714.498 443.319C713.293 443.319 712.316 444.296 712.316 445.5C712.316 446.705 713.293 447.682 714.498 447.682Z" fill="url(#paint8_radial_0_1)"/>
</g>
<circle opacity="0.82" cx="512.377" cy="356.728" r="7.55316" transform="rotate(42.4556 512.377 356.728)" stroke="white" stroke-width="2"/>
<circle opacity="0.26" cx="163.838" cy="319.949" r="7.55316" transform="rotate(42.4556 163.838 319.949)" stroke="white" stroke-width="2"/>
<circle opacity="0.26" cx="862.631" cy="319.949" r="7.55316" transform="rotate(42.4556 862.631 319.949)" stroke="white" stroke-width="2"/>
<g opacity="0.23">
<path d="M676.257 452.609L699.24 451.61" stroke="white" stroke-width="2" stroke-linecap="round"/>
<path d="M687.858 441.197L687.762 462.986" stroke="white" stroke-width="2" stroke-linecap="round"/>
</g>
<g opacity="0.23">
<path d="M339.261 452.609L362.244 451.61" stroke="white" stroke-width="2" stroke-linecap="round"/>
<path d="M350.862 441.197L350.766 462.986" stroke="white" stroke-width="2" stroke-linecap="round"/>
</g>
<path opacity="0.39" d="M789.791 7.75695C790.322 47.1054 783.326 86.1725 769.207 122.698C755.088 159.224 734.126 192.483 707.534 220.551C680.943 248.619 649.249 270.939 614.287 286.219C579.326 301.5 541.79 309.438 503.851 309.573C465.912 309.709 428.325 302.04 393.262 287.01C358.199 271.98 326.358 249.888 299.58 222.011C272.803 194.134 251.62 161.026 237.258 124.602C222.896 88.1785 215.64 49.1624 215.91 9.81108L217.504 9.82282C217.236 48.9554 224.452 87.7547 238.734 123.976C253.016 160.197 274.081 193.121 300.71 220.843C327.339 248.565 359.003 270.535 393.871 285.482C428.739 300.428 466.118 308.054 503.846 307.919C541.573 307.784 578.901 299.891 613.668 284.695C648.436 269.499 679.953 247.303 706.397 219.391C732.841 191.479 753.686 158.405 767.727 122.082C781.768 85.7599 788.725 46.9099 788.197 7.78011L789.791 7.75695Z" fill="#AFB2BA"/>
<path opacity="0.15" d="M902.704 8.1019C903.181 63.8482 893.214 119.143 873.376 170.801C853.539 222.458 824.223 269.457 787.118 309.088C750.013 348.719 705.853 380.2 657.183 401.715C608.512 423.231 556.294 434.357 503.536 434.451C450.778 434.545 398.524 423.606 349.785 402.264C301.046 380.922 256.785 349.6 219.554 310.101C182.323 270.602 152.857 223.709 132.854 172.123C112.851 120.536 102.707 65.2771 103.006 9.52943L105.228 9.54275C104.931 64.9806 115.019 119.933 134.91 171.232C154.802 222.532 184.104 269.165 221.128 308.444C258.153 347.723 302.167 378.872 350.635 400.095C399.103 421.318 451.067 432.196 503.532 432.103C555.997 432.009 607.925 420.945 656.325 399.549C704.725 378.153 748.64 346.848 785.538 307.437C822.437 268.026 851.59 221.288 871.317 169.918C891.045 118.547 900.957 63.5597 900.482 8.12314L902.704 8.1019Z" fill="#AFB2BA"/>
<path opacity="0.07" d="M1004.79 0.273839C1008 69.6982 997.513 139.073 973.949 204.232C950.384 269.391 914.232 328.989 867.663 379.447C821.093 429.904 765.068 470.181 702.952 497.857C640.837 525.533 573.911 540.037 506.197 540.499C438.482 540.961 371.375 527.371 308.906 500.545C246.437 473.718 189.896 434.21 142.677 384.392C95.4588 334.574 58.5377 275.474 34.1316 210.642C9.72557 145.811 -1.66192 76.5851 0.653114 7.12306L3.44384 7.22104C1.14167 76.2971 12.4659 145.138 36.7363 209.609C61.0068 274.081 97.7227 332.852 144.679 382.393C191.635 431.934 247.862 471.224 309.984 497.901C372.106 524.578 438.84 538.093 506.178 537.633C573.517 537.174 640.07 522.75 701.841 495.228C763.611 467.706 819.325 427.653 865.635 377.476C911.946 327.298 947.898 268.031 971.331 203.235C994.764 138.438 1005.2 69.4485 1002 0.409882L1004.79 0.273839Z" fill="#AFB2BA"/>
<defs>
<radialGradient id="paint0_radial_0_1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(714.5 445.5) rotate(90) scale(8.5 1.07031)">
<stop stop-color="#5429DE"/>
<stop offset="1" stop-opacity="0"/>
</radialGradient>
<radialGradient id="paint1_radial_0_1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(714.5 445.5) rotate(90) scale(1.07031 8.5)">
<stop stop-color="#5429DE"/>
<stop offset="1" stop-opacity="0"/>
</radialGradient>
<radialGradient id="paint2_radial_0_1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(714.499 445.499) rotate(45) scale(7.34359 0.925123)">
<stop stop-color="#5429DE"/>
<stop offset="1" stop-opacity="0"/>
</radialGradient>
<radialGradient id="paint3_radial_0_1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(714.501 445.499) rotate(90) scale(5.2348 5.23512)">
<stop stop-color="#5429DE"/>
<stop offset="1" stop-opacity="0"/>
</radialGradient>
<radialGradient id="paint4_radial_0_1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(714.499 445.499) rotate(90) scale(5.49003 2.37438)">
<stop stop-color="#5429DE"/>
<stop offset="1" stop-opacity="0"/>
</radialGradient>
<radialGradient id="paint5_radial_0_1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(714.497 445.5) rotate(90) scale(2.37632 5.48964)">
<stop stop-color="#5429DE"/>
<stop offset="1" stop-opacity="0"/>
</radialGradient>
<radialGradient id="paint6_radial_0_1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(714.494 445.499) rotate(90) scale(2.05164 4.74356)">
<stop stop-color="#5429DE"/>
<stop offset="1" stop-opacity="0"/>
</radialGradient>
<radialGradient id="paint7_radial_0_1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(714.493 445.5) rotate(90) scale(4.74279 2.05167)">
<stop stop-color="#5429DE"/>
<stop offset="1" stop-opacity="0"/>
</radialGradient>
<radialGradient id="paint8_radial_0_1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(714.498 445.5) scale(2.18113)">
<stop stop-color="white"/>
<stop offset="0.09" stop-color="#DFDFDF"/>
<stop offset="0.28" stop-color="#9C9C9C"/>
<stop offset="0.46" stop-color="#646464"/>
<stop offset="0.63" stop-color="#393939"/>
<stop offset="0.78" stop-color="#1A1A1A"/>
<stop offset="0.91" stop-color="#070707"/>
<stop offset="1"/>
</radialGradient>
</defs>
</svg>
)
}



export const Dotsgroup = () =>{
    return(<svg width="49" height="30" viewBox="0 0 49 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.53">
    <circle cx="0.831557" cy="0.831557" r="0.831557" fill="white"/>
    <circle cx="7.4839" cy="0.831557" r="0.831557" fill="white"/>
    <circle cx="14.1362" cy="0.831557" r="0.831557" fill="white"/>
    <circle cx="20.7886" cy="0.831557" r="0.831557" fill="white"/>
    <circle cx="27.4409" cy="0.831557" r="0.831557" fill="white"/>
    <circle cx="40.7456" cy="0.831557" r="0.831557" fill="white"/>
    <circle cx="34.0972" cy="0.831557" r="0.831557" fill="white"/>
    <circle cx="47.398" cy="0.831557" r="0.831557" fill="white"/>
    </g>
    <g opacity="0.53">
    <circle cx="0.831557" cy="7.67433" r="0.831557" fill="white"/>
    <circle cx="7.4839" cy="7.67433" r="0.831557" fill="white"/>
    <circle cx="14.1362" cy="7.67433" r="0.831557" fill="white"/>
    <circle cx="20.7886" cy="7.67433" r="0.831557" fill="white"/>
    <circle cx="27.4409" cy="7.67433" r="0.831557" fill="white"/>
    <circle cx="40.7456" cy="7.67433" r="0.831557" fill="white"/>
    <circle cx="34.0972" cy="7.67433" r="0.831557" fill="white"/>
    <circle cx="47.398" cy="7.67433" r="0.831557" fill="white"/>
    </g>
    <g opacity="0.53">
    <circle cx="0.831557" cy="14.5171" r="0.831557" fill="white"/>
    <circle cx="7.4839" cy="14.5171" r="0.831557" fill="white"/>
    <circle cx="14.1362" cy="14.5171" r="0.831557" fill="white"/>
    <circle cx="20.7886" cy="14.5171" r="0.831557" fill="white"/>
    <circle cx="27.4409" cy="14.5171" r="0.831557" fill="white"/>
    <circle cx="40.7456" cy="14.5171" r="0.831557" fill="white"/>
    <circle cx="34.0972" cy="14.5171" r="0.831557" fill="white"/>
    <circle cx="47.398" cy="14.5171" r="0.831557" fill="white"/>
    </g>
    <g opacity="0.53">
    <circle cx="0.831557" cy="21.3589" r="0.831557" fill="white"/>
    <circle cx="7.4839" cy="21.3589" r="0.831557" fill="white"/>
    <circle cx="14.1362" cy="21.3589" r="0.831557" fill="white"/>
    <circle cx="20.7886" cy="21.3589" r="0.831557" fill="white"/>
    <circle cx="27.4409" cy="21.3589" r="0.831557" fill="white"/>
    <circle cx="40.7456" cy="21.3589" r="0.831557" fill="white"/>
    <circle cx="34.0972" cy="21.3589" r="0.831557" fill="white"/>
    <circle cx="47.398" cy="21.3589" r="0.831557" fill="white"/>
    </g>
    <g opacity="0.53">
    <circle cx="0.831557" cy="28.2017" r="0.831557" fill="white"/>
    <circle cx="7.4839" cy="28.2017" r="0.831557" fill="white"/>
    <circle cx="14.1362" cy="28.2017" r="0.831557" fill="white"/>
    <circle cx="20.7886" cy="28.2017" r="0.831557" fill="white"/>
    <circle cx="27.4409" cy="28.2017" r="0.831557" fill="white"/>
    <circle cx="40.7456" cy="28.2017" r="0.831557" fill="white"/>
    <circle cx="34.0972" cy="28.2017" r="0.831557" fill="white"/>
    <circle cx="47.398" cy="28.2017" r="0.831557" fill="white"/>
    </g>
    </svg>
    )
}

export const SliderArc = () =>{
    return(
        <svg width="216" height="148" viewBox="0 0 216 148" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.02465 2.33759C1.97048 1.69834 2.44479 1.13525 3.08481 1.09123C12.8597 0.418985 22.6742 1.71607 31.9452 4.91006C41.5826 8.23023 50.4201 13.5269 57.8916 20.4607C65.3631 27.3945 71.3038 35.8126 75.3332 45.1755C79.3625 54.5385 81.3917 64.64 81.2905 74.8326C81.1894 85.0253 78.9603 95.0845 74.746 104.366C70.5317 113.647 64.4251 121.945 56.8176 128.73C49.21 135.514 40.2691 140.634 30.5679 143.763C21.2353 146.772 11.3969 147.874 1.63734 147.008C0.99832 146.951 0.535277 146.379 0.602114 145.741C0.668955 145.103 1.24037 144.641 1.87942 144.697C11.3172 145.53 20.8302 144.462 29.8549 141.551C39.2482 138.522 47.9052 133.565 55.2713 126.996C62.6374 120.427 68.5501 112.392 72.6307 103.405C76.7112 94.4186 78.8696 84.6787 78.9675 74.8096C79.0654 64.9405 77.1006 55.1597 73.1992 46.0939C69.2977 37.0281 63.5456 28.8773 56.3112 22.1636C49.0769 15.4499 40.5199 10.3213 31.1885 7.10657C22.2234 4.01799 12.7334 2.76131 3.281 3.40643C2.64095 3.45012 2.07882 2.97682 2.02465 2.33759Z" fill="#D9D9D9"/>
        <path d="M215.74 22.8394C215.786 22.3926 215.461 21.9924 215.013 21.955C208.811 21.4364 202.55 22.2841 196.6 24.4516C190.391 26.7135 184.656 30.3628 179.765 35.1656C174.873 39.9684 170.932 45.8189 168.193 52.3421C165.455 58.8654 163.98 65.9176 163.863 73.047C163.745 80.1764 164.988 87.2257 167.512 93.7433C170.036 100.261 173.785 106.103 178.519 110.895C183.252 115.688 188.867 119.325 195.002 121.573C200.891 123.732 207.136 124.565 213.366 124.027C213.801 123.99 214.122 123.608 214.091 123.172C214.059 122.715 213.654 122.376 213.197 122.414C207.191 122.921 201.173 122.112 195.496 120.031C189.556 117.854 184.12 114.333 179.536 109.692C174.953 105.052 171.323 99.3954 168.879 93.0847C166.436 86.774 165.232 79.9485 165.346 73.0454C165.459 66.1423 166.887 59.314 169.539 52.9978C172.19 46.6816 176.007 41.0168 180.743 36.3664C185.479 31.7161 191.031 28.1827 197.044 25.9926C202.797 23.8968 208.85 23.0754 214.847 23.573C215.294 23.6102 215.694 23.2861 215.74 22.8394Z" fill="#D9D9D9"/>
        <path d="M79.7852 74.0196H164.451" stroke="#D9D9D9" stroke-width="4"/>
        </svg>
    )
}


export const LocationIcon = () =>{
    return(<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.7">
    <circle cx="12.5" cy="12.5" r="12.1" stroke="#8E8E8F" stroke-width="0.8"/>
    <path d="M12.6318 18.5753C11.6452 17.9368 10.8172 17.177 10.165 16.3558L10.1646 16.3553C9.24214 15.1974 8.67051 13.9242 8.47587 12.713L8.47581 12.7127C8.27759 11.4855 8.46695 10.3328 9.05364 9.41002L9.05394 9.40954C9.28554 9.04412 9.58237 8.71019 9.94594 8.42032L12.6318 18.5753ZM12.6318 18.5753C13.2458 18.1958 13.7996 17.7353 14.2841 17.222V17.2212L14.3883 17.1067C15.4528 15.9375 16.1698 14.51 16.4573 13.1137L16.4573 13.1137C16.7491 11.6982 16.5976 10.3408 15.9657 9.29858C15.7774 8.98918 15.5487 8.70595 15.2784 8.46002L15.2777 8.4594C14.4921 7.74122 13.5959 7.40715 12.6957 7.40011L12.6318 18.5753ZM9.94597 8.42029C10.7989 7.74043 11.7566 7.39309 12.6956 7.4001L9.94597 8.42029Z" stroke="#C2C2C2" stroke-width="0.8"/>
    </g>
    <circle cx="12.5" cy="11.5" r="1.5" fill="#888888"/>
    </svg>
    )
}


export const MailIcon = () =>{
    return(<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.7">
    <path d="M10.403 12.1475H13.7901M9.67717 16.1597H11.6127L13.7659 17.6573C13.8385 17.7079 13.923 17.737 14.0102 17.7414C14.0975 17.7458 14.1842 17.7254 14.2612 17.6823C14.3382 17.6391 14.4025 17.575 14.4474 17.4966C14.4922 17.4183 14.5159 17.3287 14.5159 17.2374V16.1597C15.9675 16.1597 16.9352 15.1478 16.9352 13.63V10.5943C16.9352 9.07646 15.9675 8.06458 14.5159 8.06458H9.67717C8.22555 8.06458 7.25781 9.07646 7.25781 10.5943V13.63C7.25781 15.1478 8.22555 16.1597 9.67717 16.1597Z" stroke="#8E8E8F" stroke-width="0.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <circle cx="12.5" cy="12.5" r="12.1" stroke="#8E8E8F" stroke-width="0.8"/>
    </g>
    </svg>
    
    )
}

export const PhoneIcon = () =>{
    return(<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 15.3485C17 15.5105 16.9639 15.677 16.8873 15.839C16.8107 16.001 16.7116 16.154 16.5809 16.298C16.36 16.541 16.1167 16.7165 15.8418 16.829C15.5714 16.9415 15.2784 17 14.9629 17C14.5033 17 14.012 16.892 13.4937 16.6715C12.9755 16.451 12.4572 16.154 11.9434 15.7805C11.4198 15.398 10.9256 14.9769 10.4652 14.5205C10.0095 14.0624 9.58913 13.5705 9.20781 13.049C8.83826 12.536 8.54081 12.023 8.32449 11.5145C8.10816 11.0015 8 10.511 8 10.043C8 9.737 8.05408 9.4445 8.16224 9.1745C8.27041 8.9 8.44166 8.648 8.68052 8.423C8.96895 8.1395 9.28443 8 9.61793 8C9.74412 8 9.87031 8.027 9.98297 8.081C10.1002 8.135 10.2038 8.216 10.2849 8.333L11.3305 9.8045C11.4116 9.917 11.4702 10.0205 11.5108 10.1195C11.5513 10.214 11.5739 10.3085 11.5739 10.394C11.5739 10.502 11.5423 10.61 11.4792 10.7135C11.4206 10.817 11.335 10.925 11.2268 11.033L10.8843 11.3885C10.8348 11.438 10.8122 11.4965 10.8122 11.5685C10.8122 11.6045 10.8167 11.636 10.8257 11.672C10.8393 11.708 10.8528 11.735 10.8618 11.762C10.9429 11.9105 11.0826 12.104 11.2809 12.338C11.4837 12.572 11.7 12.8105 11.9344 13.049C12.1778 13.2875 12.4121 13.508 12.651 13.7105C12.8853 13.9085 13.0791 14.0435 13.2323 14.1245C13.2549 14.1335 13.2819 14.147 13.3135 14.1605C13.3495 14.174 13.3856 14.1785 13.4261 14.1785C13.5028 14.1785 13.5613 14.1515 13.6109 14.102L13.9534 13.7645C14.0661 13.652 14.1743 13.5665 14.2779 13.5125C14.3816 13.4495 14.4852 13.418 14.5979 13.418C14.6835 13.418 14.7737 13.436 14.8728 13.4765C14.972 13.517 15.0756 13.5755 15.1883 13.652L16.68 14.7095C16.7972 14.7905 16.8783 14.885 16.9279 14.9975C16.973 15.11 17 15.2225 17 15.3485Z" stroke="#636364" stroke-width="0.8" stroke-miterlimit="10"/>
    <circle cx="12.5" cy="12.5" r="12.1" stroke="#5D5D5E" stroke-width="0.8"/>
    </svg>    
    )
}

export const ForwardArrowIcon = () =>{
    return(<svg width="43" height="40" viewBox="0 0 43 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20.2793" cy="20" r="19.6" stroke="#A0A0A0" stroke-width="0.8"/>
    <path d="M21.8241 15.2L26.6801 20.056L21.8241 24.912M13.0801 20.056H26.5441" stroke="#A0A0A0" stroke-width="0.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <circle cx="40" cy="20" r="3" fill="#FF0010"/>
    </svg>      
    )
}

export const FaceBookIcon = () =>{
    return(<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.7">
    <path d="M17.9957 7.52081C18.4873 7.52081 18.9821 7.54429 19.4727 7.56363C19.7925 7.57514 20.1124 7.61519 20.4129 7.64373L20.3903 10.2385C20.2989 10.2385 20.2269 10.2385 20.155 10.2385C19.8298 10.2385 19.5052 10.2348 19.1801 10.2348C18.9364 10.2348 18.6927 10.2366 18.449 10.2444C17.6648 10.2675 17.087 10.5253 17.0471 11.3843C17.0219 12.0031 17.004 13.3691 17.003 13.4409C17.044 13.4409 17.8192 13.4436 18.5992 13.4436C18.9895 13.4436 19.3708 13.4432 19.6365 13.4409C19.8393 13.4409 20.0415 13.4409 20.2705 13.4409C20.1114 14.4376 19.9659 15.3943 19.8073 16.3565H17.0035L16.94 23.637H13.496L13.5595 16.345H10.7432L10.7684 13.4464H13.5979L13.6 13.2286C13.6052 12.6444 13.5968 12.0602 13.622 11.476C13.632 11.1095 13.6546 10.7315 13.7297 10.3646C13.9203 9.47652 14.391 8.73208 15.2387 8.19942C15.9814 7.73535 16.826 7.54061 17.7215 7.52358C17.8129 7.52127 17.9043 7.52081 17.9957 7.52081Z" fill="white"/>
    </g>
    <circle cx="15.5789" cy="15.5789" r="15.2789" stroke="#6A6A6A" stroke-width="0.6"/>
    </svg>       
    )
}

export const WhatsAppIcon = () =>{
    return(<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.7">
    <path d="M13.1357 11.8304C13.289 11.8304 13.4457 11.8577 13.5981 11.8841C13.6889 11.8963 13.7729 12.0301 13.8207 12.1273C14.0414 12.6438 14.2626 13.1599 14.4526 13.6887C14.56 13.968 14.3632 14.2356 13.9526 14.697C13.8237 14.843 13.7983 14.9827 13.8945 15.1526C14.5419 16.2644 15.4535 17.0661 16.6586 17.5402C16.7133 17.5612 16.7631 17.571 16.8094 17.571C16.9125 17.571 16.9979 17.5207 17.0785 17.4245C17.5936 16.8025 17.7406 16.5305 17.9491 16.5305C18.0013 16.5305 18.057 16.5476 18.1239 16.5803C19.7342 17.3518 19.8611 17.382 19.866 17.5647C19.908 18.8586 18.7811 19.3669 18.0179 19.3669C17.9784 19.3669 17.9398 19.3654 17.9022 19.363C16.3412 19.3083 13.8598 18.0749 12.1752 15.1106C11.582 14.0593 11.6762 12.8626 12.5693 12.0364C12.7402 11.8777 12.935 11.8304 13.1357 11.8304Z" fill="white"/>
    <path d="M23.7277 14.0408C24.4958 18.4636 21.572 22.6554 17.18 23.4268C16.7074 23.5079 16.2401 23.5479 15.7782 23.5479C14.5404 23.5479 13.3422 23.2598 12.1918 22.6978C12.0951 22.649 11.9736 22.6251 11.8603 22.6251C11.8036 22.6251 11.749 22.631 11.7001 22.6432C9.57565 23.1778 8.16357 23.5728 7.73145 23.6578C7.82519 23.3116 7.90674 23.0079 7.98779 22.7042C8.26269 21.732 8.54344 20.7662 8.79979 19.7941C8.83738 19.6481 8.82664 19.4601 8.76072 19.3263C6.3301 14.6365 8.91892 8.9564 14.0922 7.74109C14.7313 7.59168 15.3607 7.52087 15.9735 7.52087C19.8714 7.52087 23.0891 10.3822 23.7277 14.0408ZM16.8494 22.1393C20.4871 21.5563 22.9973 18.1598 22.4724 14.5393C21.9841 11.1985 19.1609 8.83922 15.9212 8.83922C15.5204 8.83922 15.1127 8.87535 14.7025 8.95054C10.2006 9.7767 7.7749 14.7888 9.98043 18.7619C10.226 19.1994 10.29 19.5636 10.1167 20.0314C9.91793 20.5963 9.79195 21.1798 9.61081 21.8175C10.4082 21.611 11.1445 21.4288 11.8754 21.2223C11.9438 21.2042 12.0063 21.1954 12.0663 21.1954C12.1889 21.1954 12.2997 21.2321 12.4213 21.3014C13.4735 21.9112 14.5853 22.2286 15.7596 22.2286C16.117 22.2281 16.4803 22.1988 16.8494 22.1393Z" fill="white"/>
    </g>
    <circle cx="15.7889" cy="15.5789" r="15.2789" stroke="#6A6A6A" stroke-width="0.6"/>
    </svg>
      
    )
}


export const TwitterIcon = () =>{
    return(<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.7">
    <path d="M13.6098 13.9263C13.4094 13.7249 13.2149 13.5121 13.026 13.2872L10.25 10.5006C10.4385 10.725 10.6334 10.9384 10.8339 11.1398L13.6098 13.9263Z" fill="#CCCCCC"/>
    <path d="M10.7109 14.2075L13.4869 16.994C13.6187 17.1263 13.7643 17.2498 13.9228 17.3636L11.1469 14.577C10.9883 14.4632 10.8427 14.3397 10.7109 14.2075Z" fill="#CCCCCC"/>
    <path d="M10.6826 16.4258L13.4586 19.2124C13.8641 19.6193 14.3719 19.8884 14.9807 20.0299L12.2042 17.2429C11.596 17.1023 11.0881 16.8332 10.6826 16.4258Z" fill="#CCCCCC"/>
    <path d="M24.1229 11.3836C24.0128 11.5499 23.9027 11.7048 23.7755 11.8536C23.4515 12.255 23.076 12.6103 22.6552 12.9144L22.6027 13.0117C22.6165 13.4646 22.5958 13.9176 22.535 14.3706C22.4128 15.3222 22.1368 16.2342 21.7285 17.0996C21.2977 17.994 20.7465 18.8023 20.053 19.5133C18.8742 20.723 17.4637 21.5027 15.8209 21.864C15.2582 21.9842 14.69 22.0474 14.1108 22.0649C14.0181 22.0676 13.926 22.069 13.8347 22.069C12.1744 22.069 10.6357 21.6266 9.21872 20.7465L9.14453 20.7004C9.38738 20.7267 9.62747 20.7396 9.86479 20.7396C10.6749 20.7396 11.4546 20.588 12.215 20.282C12.7384 20.064 13.2223 19.7889 13.6776 19.4276C12.0896 19.376 11.1007 18.2295 10.844 17.3005C11.0343 17.3378 11.2242 17.3553 11.4131 17.3553C11.6615 17.3553 11.909 17.3244 12.1569 17.2659L12.203 17.2429C11.3385 17.0424 10.6772 16.5835 10.2242 15.8383C9.91824 15.3452 9.77908 14.8006 9.78369 14.2098C10.2178 14.4393 10.6638 14.5651 11.1454 14.5766C10.5012 14.1121 10.0699 13.4987 9.89797 12.719C9.72608 11.9393 9.84636 11.1997 10.2473 10.5002C11.8892 12.4554 13.9799 13.5273 16.5411 13.7052C16.525 13.5789 16.5084 13.4757 16.4923 13.3669C16.4116 12.6444 16.566 11.9739 16.9605 11.3661C17.4535 10.5979 18.1512 10.1219 19.0466 9.97856C19.2369 9.94676 19.4231 9.93109 19.6042 9.93109C20.406 9.93109 21.1152 10.2389 21.7235 10.8329C21.7617 10.867 21.7935 10.8854 21.8359 10.8854C21.8502 10.8854 21.8663 10.8831 21.8838 10.879C22.5156 10.7472 23.1138 10.5177 23.6723 10.1965L23.7124 10.1735C23.4889 10.8845 23.0433 11.4347 22.4096 11.8421C23.0078 11.7794 23.5709 11.6186 24.1229 11.3836Z" fill="white"/>
    </g>
    <circle cx="16.3164" cy="16" r="15.7" stroke="#6A6A6A" stroke-width="0.6"/>
    </svg>    
    )
}

export const InstagramIcon = () =>{
    return(<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.7">
    <path d="M20.116 11.3226C20.1197 11.3226 20.123 11.3226 20.1267 11.3226C20.5986 11.3226 20.9817 11.7087 20.978 12.1863C20.9743 12.6585 20.5857 13.0445 20.1082 13.0445C19.6302 13.039 19.2536 12.6529 19.2569 12.1808C19.2601 11.7064 19.6427 11.3226 20.116 11.3226Z" fill="white"/>
    <path d="M23.3479 12.4288C23.4543 13.5582 23.5233 18.8932 23.1909 20.1435C22.7466 21.78 21.6797 22.7478 20.0244 23.0126C19.4061 23.1122 17.736 23.1724 16.0683 23.1724C14.3612 23.1724 12.656 23.1094 12.0835 22.9608C10.4569 22.5403 9.48584 21.4799 9.22802 19.8323C9.03593 18.6223 9.0151 12.9993 9.36411 11.7778C9.81957 10.1876 10.8805 9.25406 12.4894 8.98919C13.2184 8.86972 14.6441 8.82758 16.0831 8.82758C17.4217 8.82758 18.7724 8.86369 19.5852 8.90815C20.5697 8.96001 21.4714 9.27675 22.2032 9.97968C22.9007 10.6483 23.2571 11.4837 23.3479 12.4288ZM21.9685 19.5962C22.174 18.3343 22.1745 13.731 22.0569 12.5497C21.9282 11.2878 21.1571 10.4585 19.9087 10.2626C19.2908 10.1686 17.8031 10.1274 16.3229 10.1274C14.8949 10.1274 13.4739 10.1663 12.8481 10.2339C11.5974 10.3779 10.7564 11.1498 10.5569 12.3946C10.3509 13.7138 10.3537 18.6459 10.5074 19.6369C10.6666 20.6681 11.2484 21.3771 12.2774 21.6651C12.8338 21.8212 14.5006 21.8772 16.1345 21.8772C17.6388 21.8772 19.1158 21.8295 19.6713 21.7689C20.934 21.6299 21.769 20.852 21.9685 19.5962Z" fill="white"/>
    <path d="M16.2931 12.3252C18.3205 12.3252 19.9549 13.9732 19.9391 16.007C19.9239 18.0352 18.264 19.6828 16.2311 19.6828C14.1982 19.6828 12.5693 18.0352 12.5851 16.0014C12.6008 13.9672 14.2607 12.3191 16.2931 12.3252ZM16.2399 18.3807C17.5433 18.3807 18.6167 17.3304 18.638 16.0241C18.6598 14.7048 17.6137 13.6333 16.301 13.6217C16.2936 13.6217 16.2866 13.6217 16.2797 13.6217C14.9763 13.6217 13.9029 14.672 13.8816 15.9783C13.8603 17.292 14.9059 18.3691 16.2186 18.3807C16.2255 18.3807 16.2325 18.3807 16.2399 18.3807Z" fill="white"/>
    </g>
    <circle cx="16.2637" cy="16" r="15.7" stroke="#6A6A6A" stroke-width="0.6"/>
    </svg>
     
    )
}

export const LinkedInIcon = () =>{
    return(<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.7">
    <path d="M11.2666 8.66962C12.0875 8.66962 12.7413 9.33381 12.7352 10.1495C12.7292 10.9652 12.065 11.6294 11.244 11.6294C10.4283 11.6294 9.76935 10.9652 9.77543 10.1495C9.78151 9.33381 10.4514 8.66962 11.2666 8.66962Z" fill="white"/>
    <path d="M12.5167 12.7477L12.4542 20.9629H9.89941L9.96193 12.7477H12.5167Z" fill="white"/>
    <path d="M19.0165 12.5476C21.6039 12.5476 22.0662 14.2437 22.0493 16.4581L22.015 20.9629H19.4654L19.4958 16.9712C19.5032 16.0153 19.491 14.7889 18.1839 14.7889C16.8551 14.7889 16.6476 15.8261 16.6393 16.9009L16.6085 20.9629H14.0537L14.1162 12.7477H16.5629L16.5542 13.8712H16.592C16.9371 13.2283 17.7741 12.5476 19.0165 12.5476Z" fill="white"/>
    </g>
    <circle cx="15.6854" cy="15.5789" r="15.2789" stroke="#6A6A6A" stroke-width="0.6"/>
    </svg>        
    )
}


export const Polygon = () =>{
    return(<svg width="60" height="46" viewBox="0 0 60 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.48334 40L24 0.999999L46.5167 40H1.48334Z" stroke="white"/>
    <path d="M36 5L59.3827 45.5H12.6173L36 5Z" fill="white"/>
    </svg>
    )
}


export const QuestionsDesign = () =>{
    return(<svg width="47" height="40" viewBox="0 0 47 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.99" cx="6.58971" cy="6.58964" r="6.07979" transform="rotate(42.4556 6.58971 6.58964)" fill="#FAFAFA"/>
    <g opacity="0.82">
    <path d="M18.2303 26.2291L45.1015 25.0616" stroke="white" stroke-width="2" stroke-linecap="round"/>
    <path d="M31.7929 12.8865L31.6801 38.3621" stroke="white" stroke-width="2" stroke-linecap="round"/>
    </g>
    </svg>
    )
}

export const QuestionsEllipse = () =>{
    return(<svg width="65" height="73" viewBox="0 0 65 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.82" cx="17.6969" cy="54.6968" r="17.3142" transform="rotate(-81.4463 17.6969 54.6968)" fill="url(#paint0_linear_0_1)"/>
    <circle opacity="0.82" cx="55.1258" cy="9.12585" r="8.90094" transform="rotate(-81.4463 55.1258 9.12585)" fill="url(#paint1_linear_0_1)"/>
    <defs>
    <linearGradient id="paint0_linear_0_1" x1="17.6969" y1="37.3826" x2="17.6969" y2="72.011" gradientUnits="userSpaceOnUse">
    <stop stop-color="#152CFF"/>
    <stop offset="1" stop-color="#288BFF"/>
    </linearGradient>
    <linearGradient id="paint1_linear_0_1" x1="55.1258" y1="0.224916" x2="55.1258" y2="18.0268" gradientUnits="userSpaceOnUse">
    <stop stop-color="#152CFF"/>
    <stop offset="1" stop-color="#288BFF"/>
    </linearGradient>
    </defs>
    </svg>
    )
}

export const FaceSideShade = () =>{
    return(<svg width="730" height="339" viewBox="0 0 730 339" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M305.571 28.0726C306.574 27.7844 307.687 28.41 307.977 29.4122C308.247 30.3494 307.601 31.3843 306.616 31.692L187.159 66.2759C186.165 66.5594 185.221 65.9773 184.951 65.0404C184.659 64.0297 185.129 62.9642 186.123 62.6642L305.571 28.0726ZM172.869 66.5062C173.856 66.2275 174.812 66.8675 175.112 67.8861C175.378 68.8314 174.883 69.8271 173.894 70.123L165.053 72.6803C164.05 72.9603 162.959 72.4211 162.698 71.4924C162.415 70.4725 163.015 69.3654 164.017 69.0643L172.869 66.5062Z" fill="url(#paint0_linear_0_1)"/>
    <g opacity="0.12">
    <path d="M729.469 20.7746L317.853 134.518L317.533 131.173L729.072 17.479L729.469 20.7746Z" fill="#FF91A3"/>
    <path d="M545.999 208.1L-22.8392 338.812L-23.4805 335.483L545.252 204.824L545.999 208.1Z" fill="#FF91A3"/>
    </g>
    <defs>
    <linearGradient id="paint0_linear_0_1" x1="191.407" y1="80.2484" x2="438.559" y2="0.796972" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF6A76"/>
    <stop offset="1" stop-color="#E5CA18"/>
    </linearGradient>
    </defs>
    </svg>
    
    )
}


export const FaceSideShade2 = () =>{
    return(<svg width="849" height="357" viewBox="0 0 849 357" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.15">
    <path d="M948.056 3.67056L339.262 162.412L338.467 158.846L947.149 0.164212L948.056 3.67056Z" fill="#0FA58E"/>
    <path d="M579.86 224.167L2 357L0.5 353L579.056 220.636L579.86 224.167Z" fill="#0FA58E"/>
    </g>
    <path d="M335.757 86.1379C336.717 85.8358 337.805 86.4123 338.11 87.3718C338.392 88.2691 337.795 89.2827 336.852 89.6033L222.481 125.837C221.528 126.134 220.604 125.596 220.321 124.698C220.016 123.731 220.443 122.692 221.394 122.379L335.757 86.1379ZM208.705 126.403C209.65 126.111 210.588 126.705 210.902 127.68C211.181 128.585 210.728 129.558 209.78 129.867L201.316 132.546C200.355 132.84 199.291 132.347 199.016 131.457C198.718 130.481 199.27 129.398 200.229 129.084L208.705 126.403Z" fill="url(#paint0_linear_0_1)"/>
    <defs>
    <linearGradient id="paint0_linear_0_1" x1="190.844" y1="128.944" x2="315.679" y2="58.264" gradientUnits="userSpaceOnUse">
    <stop stop-color="#10CEAC"/>
    <stop offset="1" stop-color="#0A2432"/>
    </linearGradient>
    </defs>
    </svg>    
    )
}


export const FaceSideShade3 = () =>{
    return(<svg width="854" height="359" viewBox="0 0 854 359" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.17" d="M853.544 3.12609L322.172 125.79L321.573 122.667L852.846 0.0517578L853.544 3.12609Z" fill="#FF59E3"/>
    <path opacity="0.17" d="M532.427 236.226L1.05544 358.89L0.456421 355.766L531.73 233.151L532.427 236.226Z" fill="#FF59E3"/>
    <path d="M269.933 40.2245C270.853 39.9015 271.924 40.4245 272.249 41.3431C272.551 42.2022 272.006 43.202 271.104 43.5423L161.607 82.2556C160.695 82.5738 159.784 82.0822 159.481 81.2233C159.155 80.2968 159.535 79.278 160.445 78.9445L269.933 40.2245ZM148.297 83.2443C149.202 82.9311 150.128 83.4759 150.463 84.4093C150.762 85.2762 150.355 86.2313 149.448 86.5608L141.345 89.4238C140.424 89.7392 139.378 89.2955 139.085 88.4439C138.765 87.5084 139.264 86.4437 140.182 86.1088L148.297 83.2443Z" fill="url(#paint0_linear_0_1)"/>
    <defs>
    <linearGradient id="paint0_linear_0_1" x1="131.097" y1="86.2736" x2="279.029" y2="25.3927" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF50E9"/>
    <stop offset="1"/>
    </linearGradient>
    </defs>
    </svg>
    )    
}


export const FaceSideShade4 = () =>{
    return(<svg width="856" height="346" viewBox="0 0 856 346" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M318.445 51.0416C319.499 50.7687 320.644 51.4523 320.919 52.5063C321.175 53.4919 320.472 54.5541 319.436 54.8481L193.806 87.6395C192.76 87.9079 191.791 87.2743 191.535 86.289C191.259 85.226 191.778 84.127 192.824 83.8414L318.445 51.0416ZM178.885 87.4847C179.923 87.2212 180.903 87.9156 181.188 88.9869C181.44 89.9809 180.896 91.0063 179.855 91.2878L170.558 93.7124C169.503 93.9769 168.379 93.3839 168.133 92.4075C167.865 91.3352 168.522 90.1963 169.576 89.9098L178.885 87.4847Z" fill="url(#paint0_linear_0_1)"/>
    <g opacity="0.15">
    <path d="M953.244 3.97522L359.357 140.443L358.688 136.968L952.465 0.554932L953.244 3.97522Z" fill="#D44E4E"/>
    <path d="M594.556 208.887L0.669491 345.354L0 341.879L593.777 205.466L594.556 208.887Z" fill="#D44E4E"/>
    </g>
    <defs>
    <linearGradient id="paint0_linear_0_1" x1="159.439" y1="89.236" x2="298.297" y2="19.7975" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FC5A34"/>
    <stop offset="1"/>
    </linearGradient>
    </defs>
    </svg>
    
    )    
}

export const CommentsIcon = () =>{
    return(<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.55 6.4848H10.45M4.5 11.8744H7.3L10.415 13.8862C10.5201 13.9543 10.6423 13.9933 10.7685 13.9992C10.8947 14.0051 11.0202 13.9777 11.1316 13.9197C11.2429 13.8618 11.336 13.7757 11.4009 13.6704C11.4658 13.5652 11.5001 13.4448 11.5 13.3221V11.8744C13.6 11.8744 15 10.5151 15 8.47618V4.39826C15 2.35931 13.6 1 11.5 1H4.5C2.4 1 1 2.35931 1 4.39826V8.47618C1 10.5151 2.4 11.8744 4.5 11.8744Z" stroke="#DDDDDD" stroke-width="0.9" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    )
}


export const Servicesline1 = () =>{
    return(<svg width="209" height="6" viewBox="0 0 209 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.638672 3L4.13867 5.02073V0.979274L0.638672 3ZM3.78867 3.35H278.257V2.65H3.78867V3.35Z" fill="#FF5065"/>
    </svg>
    )
}

export const Servicesline2 = () =>{
    return(<svg width="215" height="6" viewBox="0 0 215 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 3L3.5 5.02073L3.5 0.979274L0 3ZM3.15 3.35L338.79 3.34997L338.79 2.64997L3.15 2.65L3.15 3.35Z" fill="#01A3BA"/>
    </svg>
    
    )
}

export const Servicesline3 = () =>{
    return(<svg width="215" height="6" viewBox="0 0 215 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 3L3.5 5.02073L3.5 0.979274L0 3ZM3.15 3.35L338.79 3.34997L338.79 2.64997L3.15 2.65L3.15 3.35Z" fill="#A6369E"/>
    </svg>
    
    
    )
}


export const Servicesline1Desktop = () =>{
    return(<svg width="888" height="6" viewBox="0 0 888 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M888 2.99992L884.5 0.979197L884.5 5.02065L888 2.99992ZM3.0598e-08 3.35L884.85 3.34992L884.85 2.64992L-3.0598e-08 2.65L3.0598e-08 3.35Z" fill="#FF5065"/>
    </svg>    
    )
}


export const RightArrowMobile = () =>{
    return(<svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.35824 1L14 5.5L9.35824 10M1 5.5H13.87" stroke="#FCFCFC" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    )
}

export const LineSmall = () =>{
    return(<svg width="333" height="2" viewBox="0 0 333 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 1H333" stroke="#B3B3B3" stroke-width="0.5"/>
    </svg>
    )
}


export const RighArrowIcon = () =>{
    return(<svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L5.39166 5.02569C6.2564 5.81836 6.2564 7.18164 5.39166 7.97431L1 12" stroke="#D5D5D5" stroke-width="2" stroke-linecap="round"/>
    </svg>
    )
}

export const TabletIcon = () =>{
    return(<svg width="50" height="32" viewBox="0 0 50 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M46 22V11" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
    <rect x="0.75" y="31.25" width="30.5" height="48.5" rx="1.25" transform="rotate(-90 0.75 31.25)" stroke="white" stroke-width="1.5"/>
    </svg>
    )
}

export const TvIcon = () =>{
    return(<svg width="37" height="27" viewBox="0 0 37 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.8" y="0.8" width="35.4" height="21.4" stroke="white" stroke-width="1.6"/>
    <path d="M19 23V26" stroke="white" stroke-width="1.6" stroke-linecap="round"/>
    <path d="M15 26H23" stroke="white" stroke-width="1.6" stroke-linecap="round"/>
    </svg>
    )
}

export const  VerticalLine = () =>{
    return( <svg width="2" height="21" viewBox="0 0 2 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.5" d="M1 0L1 21" stroke="#616161"/>
    </svg>
    )
}

export const Instasidebar = () =>{
    return(<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.4" cx="16" cy="16" r="16" fill="#595959"/>
    <g opacity="0.7">
    <path d="M19.8511 11.3223C19.8548 11.3223 19.8581 11.3223 19.8618 11.3223C20.3337 11.3223 20.7168 11.7083 20.7131 12.186C20.7094 12.6581 20.3208 13.0441 19.8433 13.0441C19.3653 13.0386 18.9887 12.6526 18.992 12.1804C18.9952 11.706 19.3778 11.3223 19.8511 11.3223Z" fill="#B7B7B7"/>
    <g opacity="0.66">
    <path d="M23.0832 12.4294C23.1897 13.5588 23.2586 18.8938 22.9263 20.144C22.4819 21.7805 21.415 22.7483 19.7598 23.0132C19.1414 23.1128 17.4714 23.173 15.8037 23.173C14.0966 23.173 12.3914 23.11 11.8188 22.9613C10.1923 22.5409 9.22119 21.4804 8.96337 19.8329C8.77128 18.6229 8.75045 12.9999 9.09946 11.7783C9.55492 10.1881 10.6158 9.25461 12.2248 8.98974C12.9538 8.87027 14.3794 8.82812 15.8185 8.82812C17.1571 8.82812 18.5077 8.86424 19.3205 8.9087C20.3051 8.96056 21.2067 9.2773 21.9385 9.98023C22.6361 10.6489 22.9925 11.4843 23.0832 12.4294ZM21.7038 19.5967C21.9094 18.3349 21.9098 13.7315 21.7923 12.5502C21.6636 11.2884 20.8924 10.459 19.6441 10.2632C19.0262 10.1692 17.5385 10.1279 16.0582 10.1279C14.6303 10.1279 13.2093 10.1668 12.5835 10.2345C11.3328 10.3785 10.4918 11.1504 10.2923 12.3951C10.0863 13.7144 10.0891 18.6465 10.2427 19.6375C10.402 20.6687 10.9838 21.3776 12.0128 21.6657C12.5691 21.8217 14.2359 21.8778 15.8698 21.8778C17.3742 21.8778 18.8512 21.8301 19.4066 21.7694C20.6693 21.6305 21.5044 20.8525 21.7038 19.5967Z" fill="white"/>
    </g>
    <path d="M16.0285 12.3262C18.0558 12.3262 19.6902 13.9742 19.6745 16.008C19.6592 18.0362 17.9994 19.6838 15.9664 19.6838C13.9335 19.6838 12.3047 18.0362 12.3204 16.0025C12.3362 13.9682 13.996 12.3202 16.0285 12.3262ZM15.9752 18.3817C17.2787 18.3817 18.3521 17.3315 18.3734 16.0252C18.3951 14.7059 17.349 13.6343 16.0363 13.6228C16.0289 13.6228 16.022 13.6228 16.0151 13.6228C14.7116 13.6228 13.6382 14.673 13.6169 15.9793C13.5956 17.293 14.6413 18.3701 15.954 18.3817C15.9609 18.3817 15.9678 18.3817 15.9752 18.3817Z" fill="#B7B7B7"/>
    </g>
    </svg>
    )
}


export const WhatsappSideBar = () =>{
    return(<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.4" cx="15.5789" cy="15.5789" r="15.5789" fill="#595959"/>
    <g opacity="0.99">
    <path d="M12.9235 11.8301C13.0768 11.8301 13.2336 11.8574 13.3859 11.8838C13.4767 11.896 13.5607 12.0298 13.6086 12.1269C13.8293 12.6435 14.0505 13.1596 14.2404 13.6884C14.3478 13.9677 14.151 14.2353 13.7404 14.6967C13.6115 14.8427 13.5861 14.9824 13.6823 15.1523C14.3297 16.2641 15.2414 17.0658 16.4464 17.5399C16.5011 17.5609 16.5509 17.5707 16.5973 17.5707C16.7003 17.5707 16.7858 17.5204 16.8663 17.4242C17.3815 16.8022 17.5284 16.5302 17.7369 16.5302C17.7892 16.5302 17.8448 16.5473 17.9117 16.58C19.522 17.3515 19.649 17.3817 19.6539 17.5644C19.6959 18.8583 18.5689 19.3666 17.8058 19.3666C17.7662 19.3666 17.7276 19.3651 17.69 19.3627C16.129 19.308 13.6476 18.0746 11.9631 15.1103C11.3698 14.059 11.4641 12.8623 12.3571 12.0361C12.528 11.8774 12.7228 11.8301 12.9235 11.8301Z" fill="#767676"/>
    <path d="M23.5158 14.0414C24.2839 18.4642 21.3601 22.656 16.9681 23.4274C16.4954 23.5085 16.0282 23.5485 15.5663 23.5485C14.3285 23.5485 13.1303 23.2605 11.9799 22.6985C11.8832 22.6496 11.7616 22.6257 11.6484 22.6257C11.5917 22.6257 11.537 22.6316 11.4882 22.6438C9.36374 23.1784 7.95165 23.5734 7.51953 23.6584C7.61328 23.3122 7.69482 23.0085 7.77588 22.7048C8.05077 21.7327 8.33153 20.7668 8.58787 19.7947C8.62547 19.6487 8.61473 19.4607 8.54881 19.3269C6.11819 14.6371 8.70701 8.95701 13.8803 7.7417C14.5194 7.59229 15.1488 7.52148 15.7616 7.52148C19.6594 7.52148 22.8772 10.3828 23.5158 14.0414ZM16.6375 22.1399C20.2752 21.5569 22.7854 18.1605 22.2605 14.5399C21.7722 11.1992 18.949 8.83983 15.7093 8.83983C15.3085 8.83983 14.9007 8.87596 14.4906 8.95115C9.98873 9.77731 7.56299 14.7894 9.76852 18.7625C10.0141 19.2 10.0781 19.5642 9.90474 20.032C9.70602 20.5969 9.58004 21.1804 9.39889 21.8181C10.1962 21.6116 10.9326 21.4294 11.6635 21.2229C11.7319 21.2048 11.7944 21.196 11.8544 21.196C11.977 21.196 12.0878 21.2327 12.2094 21.302C13.2616 21.9118 14.3734 22.2292 15.5477 22.2292C15.9051 22.2287 16.2684 22.1994 16.6375 22.1399Z" fill="#767676"/>
    </g>
    </svg>
    
    )
}

export const LinkedInSideBar = () =>{
    return(<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.4" cx="15.5789" cy="15.5789" r="15.5789" fill="#595959"/>
    <g opacity="0.99">
    <path d="M11.1612 8.66992C11.9821 8.66992 12.6358 9.33412 12.6298 10.1498C12.6237 10.9655 11.9595 11.6297 11.1386 11.6297C10.3229 11.6297 9.66389 10.9655 9.66996 10.1498C9.67604 9.33412 10.3459 8.66992 11.1612 8.66992Z" fill="#767676"/>
    <path d="M12.4112 12.748L12.3487 20.9633H9.79395L9.85646 12.748H12.4112Z" fill="#767676"/>
    <path d="M18.9111 12.5488C21.4984 12.5488 21.9607 14.2449 21.9438 16.4593L21.9095 20.9642H19.3599L19.3903 16.9725C19.3977 16.0165 19.3855 14.7902 18.0784 14.7902C16.7496 14.7902 16.5421 15.8273 16.5338 16.9021L16.503 20.9642H13.9482L14.0108 12.749H16.4574L16.4487 13.8724H16.4865C16.8316 13.2295 17.6686 12.5488 18.9111 12.5488Z" fill="#767676"/>
    </g>
    </svg>
    
    )
}

export const Locate = () =>{
    return(<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.5 14.125C9.99184 14.125 11.4226 13.5324 12.4775 12.4775C13.5324 11.4226 14.125 9.99184 14.125 8.5C14.125 7.00816 13.5324 5.57742 12.4775 4.52252C11.4226 3.46763 9.99184 2.875 8.5 2.875C7.00816 2.875 5.57742 3.46763 4.52252 4.52252C3.46763 5.57742 2.875 7.00816 2.875 8.5C2.875 9.99184 3.46763 11.4226 4.52252 12.4775C5.57742 13.5324 7.00816 14.125 8.5 14.125Z" stroke="#FCFCFC" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.5 2.5V1M2.5 8.5H1M8.5 14.5V16M14.5 8.5H16M8.5 10.75C9.09674 10.75 9.66903 10.5129 10.091 10.091C10.5129 9.66903 10.75 9.09674 10.75 8.5C10.75 7.90326 10.5129 7.33097 10.091 6.90901C9.66903 6.48705 9.09674 6.25 8.5 6.25C7.90326 6.25 7.33097 6.48705 6.90901 6.90901C6.48705 7.33097 6.25 7.90326 6.25 8.5C6.25 9.09674 6.48705 9.66903 6.90901 10.091C7.33097 10.5129 7.90326 10.75 8.5 10.75Z" stroke="#FCFCFC" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    )
}


export const SliderArrow = () =>{
    return(<svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 19L2.65093 12.0025C1.78302 11.1761 1.78302 9.82386 2.65093 8.99748L10 2" stroke="#444444" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
    )
}

export const SliderNext = () =>{
    return(<svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 19L9.34907 12.0025C10.217 11.1761 10.217 9.82386 9.34907 8.99748L2 2" stroke="#444444" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>    
    )
}