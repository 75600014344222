export const ServiceButton = (props: { content: any }) => {
	const content = props.content;

	return (
		<h1 className='text-[rgb(206,206,206)] ml-10 mt-32 text-xl font-extralight'>
			<span className='border border-solid border-gray-700 rounded-full px-5 py-1'>
				{content}
			</span>
		</h1>
	);
};

export const DownloadIcon = () => {
	return (
		<svg
			width='15'
			height='15'
			viewBox='0 0 15 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				opacity='0.99'
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M0.576923 9.375C0.895547 9.375 1.15385 9.62685 1.15385 9.9375C1.15385 11.0141 1.15507 11.7649 1.23319 12.3314C1.30908 12.8818 1.44788 13.1731 1.66078 13.3807C1.87367 13.5883 2.17258 13.7236 2.73702 13.7977C3.31805 13.8738 4.08815 13.875 5.19231 13.875H9.80769C10.9118 13.875 11.6819 13.8738 12.263 13.7977C12.8275 13.7236 13.1263 13.5883 13.3392 13.3807C13.5522 13.1731 13.6909 12.8818 13.7668 12.3314C13.8449 11.7649 13.8462 11.0141 13.8462 9.9375C13.8462 9.62685 14.1045 9.375 14.4231 9.375C14.7417 9.375 15 9.62685 15 9.9375V9.97868C15 11.0044 15 11.8311 14.9104 12.4813C14.8173 13.1564 14.6182 13.7248 14.1552 14.1762C13.6921 14.6277 13.1092 14.8219 12.4168 14.9126C11.7498 15 10.9019 15 9.84992 15H5.1501C4.09811 15 3.25017 15 2.58327 14.9126C1.89088 14.8219 1.3079 14.6277 0.844885 14.1763C0.38187 13.7248 0.182723 13.1564 0.0896311 12.4813C-3.04213e-05 11.8311 -1.49816e-05 11.0044 4.03041e-07 9.97868C4.03041e-07 9.96495 4.03041e-07 9.95122 4.03041e-07 9.9375C4.03041e-07 9.62685 0.2583 9.375 0.576923 9.375Z'
				fill='#FCFEFF'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M7.5 11.25C7.66626 11.25 7.82479 11.1813 7.93697 11.0608L11.0949 7.66637C11.3155 7.42919 11.2988 7.06112 11.0574 6.84427C10.8161 6.62741 10.4416 6.64386 10.2209 6.88104L8.0921 9.16929V0.581897C8.0921 0.260527 7.827 0 7.5 0C7.173 0 6.90789 0.260527 6.90789 0.581897V9.16929L4.7791 6.88104C4.55844 6.64386 4.18392 6.62741 3.94258 6.84427C3.70123 7.06112 3.68446 7.42919 3.90512 7.66637L7.06303 11.0608C7.17521 11.1813 7.33374 11.25 7.5 11.25Z'
				fill='#FCFEFF'
			/>
		</svg>
	);
};
