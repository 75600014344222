import React, { useState } from 'react';
import Human from '../../svgs/Human.png';
import { Parallax, ParallaxProvider, useParallax } from 'react-scroll-parallax';
import { Decors } from '../HomePage/decors';
import { Polygon, QuestionsDesign, QuestionsEllipse, ScribbleSide, ScribbleTop } from '../../svgs/scribble';


export const Questions = () => {
  const backgroundWidth = '800px'
  const backgroundHeight = '300px';

  const [isLight,setIsLight] = useState(false);
  const HandleLight = () =>{
    setIsLight(!isLight);
}

const ser1parallax = useParallax<HTMLDivElement>({
  scale: [0.8, 2.5, 'easeInQuad'],
  translateY: [240, -240],
  speed: -100
});

  return (
    <ParallaxProvider>
      <section
        className="h-[600px] flex justify-center items-center relative overflow-hidden" onMouseEnter={()=>HandleLight()} onMouseLeave={()=>HandleLight()}>
          <div className='rounded-2xl absolute -z-10' style={{
          background: 'linear-gradient(218deg, #0080D8 0%, #0043C4 98.65%)',
          width: backgroundWidth,   
          height: backgroundHeight, 
        }}/>
        <div
        className={`light15 ${isLight?'block':'hidden'} top-[30vh] -right-[5vw] z-[9999]`}
                    />
        <div className="absolute top-0 ml-[160px] -z-10">
                <ScribbleTop/>
        </div>
        <div className="absolute top-[50%] right-0 m-auto">
            <ScribbleSide/>
        </div>
        <div className='relative flex justify-center items-center'>
        <div>
          <div ref={ser1parallax.ref}>
            <img src={Human} alt="Human" />
          </div>
        </div>
        <div style={{ paddingRight: '5%' }}>
          <h1 className="text-3xl font-bold" style={{ color: '#FFFFFF', marginBottom: '2%' }}>
            Have any Questions on Mind?
          </h1>
          <h1 className="text-xl font-bold" style={{ color: '#FFFFFF', marginBottom: '4%' }}>
            Let's talk about your business plan
          </h1>
          <div className='flex'>
          <input
            type="text"
            className="textbox"
            placeholder="Type something..."
            style={{
              boxSizing: 'border-box',
              color: '#FFFFFF',
              width: '300px',
              height: '40px',
              background: 'rgba(255, 255, 255, 0.25)',
              border: '2px solid #FFFFFF',
              borderRadius: '10px',
              paddingLeft: '20px',
            }}
          />
          <button
            className="button"
            style={{
              width: '110px',
              height: '40px',
              background: 'rgba(255, 255, 255, 0.9)',
              backdropFilter: 'blur(2px)',
              borderRadius: '10px',
              color: '#28375F',
              fontWeight: '700',
              marginLeft: '10px',
            }}
          >
            Let's Talk
          </button>
          <div className='absolute bottom-10 right-12'>
              <Polygon/>
            </div>
            <div className='absolute bottom-28 -right-[70px]'>
              <QuestionsDesign/>
            </div>
            <div className='absolute -top-12 -right-[70px]'>
              <QuestionsEllipse/>
            </div>
          </div>
        </div>
        </div>
      </section>
    </ParallaxProvider>
  );
};
