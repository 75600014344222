import HandLight from "../../svgs/hand-light.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {
	Line,
	LineSmall,
	MobileIcon,
	SmallLine,
	TabletIcon,
	TvIcon,
} from "../../svgs/scribble";
import Aos from "aos";
import { DownloadIcon } from "../Common/services-button";

export const AboutMobile = () => {
	Aos.init();
	var settings = {
		dots: true,
		infinite: true,
		arrows: false,
		autoplay: true,
		vertical: false,
		speed: 500,
	};
	return (
		<>
			<section className='mx-auto overflow-hidden mb-20' id='about'>
				<div className='flex justify-center'>
					<img src={HandLight} data-aos='fade-up' />
				</div>
				<div className='flex justify-center'>
					<LineSmall />
				</div>
				<Slider
					{...settings}
					className='flex justify-center pt-5  overflow-hidden'
					data-aos='fade-up'
				>
					<div className='mb-5'>
						<p className='text-white font-poppins  tracking-widest justify-center text-center font-extralight flex gap-2'>
							We create lovable{" "}
							<span>
								<i>
									<MobileIcon />
								</i>
							</span>{" "}
							products going
						</p>
						<p className='text-white font-poppins tracking-widest text-center font-extralight'>
							from vision to lunch
						</p>
					</div>
					<div className='mb-5'>
						<p className='text-white font-poppins tracking-widest font-extralight justify-center text-center flex gap-2'>
							We make memorable{" "}
							<span>
								{" "}
								<i>
									<TabletIcon />
								</i>
							</span>{" "}
							sites that
						</p>
						<p className='text-white font-poppins tracking-widest text-center font-extralight'>
							grow your business
						</p>
					</div>
					<div className='mb-5'>
						<p className='text-white font-poppins tracking-widest font-extralight justify-center text-center flex gap-2'>
							We build fun{" "}
							<span>
								<i>
									<TvIcon />
								</i>
							</span>{" "}
							experience using
						</p>
						<p className='text-white font-poppins tracking-widest text-center font-extralight'>
							cutting edge technologies.
						</p>
					</div>
				</Slider>
				<div className='flex justify-center'>
					<a href='/brochure.pdf' target='blank'>
						<button className='download flex gap-3 items-center justify-center px-4 py-2 -mt-1 relative z-20 text-white'>
							<i>
								<DownloadIcon />
							</i>
							<span>Download</span>
						</button>
					</a>
				</div>
			</section>
		</>
	);
};
