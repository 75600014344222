import React from 'react';
import ContactUs from '../../svgs/Contact Us.png';
import { ParallaxProvider, useParallax } from 'react-scroll-parallax';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import {  FaceBookIcon, ForwardArrowIcon, InstagramIcon, Line2, LinkedInIcon, LocationIcon, MailIcon, PhoneIcon, TwitterIcon, WhatsAppIcon } from '../../svgs/scribble';
import maps from '../../svgs/maps.png'
import { Decors } from '../HomePage/decors';


export const Contactus = () => {


  return (
    <ParallaxProvider>
      <section id="contact" className="container mx-auto h-auto mb-10 overflow-hidden relative">
        <div className='flex'>
          <div className='w-1/2 mt-[18vh] ml-20'>
            <div className='bg-black w-full h-full'>
              <div className='flex flex-col gap-14'>
              <div className='flex flex-col gap-8'>
                <h1 className='text-white text-6xl'>Contact Us</h1>
                <div className='flex gap-8 items-center justify-start'>
                <i><LocationIcon/></i>
                <p className='text-white'>HB-47, Panampilly Nagar, Kochi, Kerala, 65467</p>
                </div>
                <div className='flex gap-8 items-center justify-start'>
                <i><MailIcon/></i>
                <p className='text-white'>Hello@zlancreation.com</p>
                </div>
                <div className='flex gap-8 items-center justify-start'>
                <i><PhoneIcon/></i>
                <p className='text-white border-r border-[#636364] pr-5'>+91 9539035376</p>
                <p className='text-white'>+44 7748451605</p>
                </div>
            </div>
            <div className='flex flex-col gap-8'>
              <h1 className='text-white text-4xl'>Get in touch</h1>
              <div className='flex gap-8'>
              <form className='flex flex-col gap-8 w-1/2'>
                <input placeholder='Enter email ID' className='bg-black p-2 border-b-[#636364] text-[#636364] border-b-2 outline-none focus-visible:border-b-2'/>
                <input placeholder='Type Description' className='bg-black p-2 border-b-[#636364] text-[#636364] border-b-2 outline-none focus-visible:border-b-2'/>
              </form>
              <div className='flex items-center mt-auto gap-5'>
                <p className='text-white text-xl cursor-pointer' >Send</p>
                <i className='cursor-pointer'><ForwardArrowIcon/></i>
              </div>
              </div>
            </div>
            <ul className='flex gap-4'>
              <li><i><FaceBookIcon/></i></li>
              <a href='https://www.linkedin.com/company/zlancreationspvtltd' target='blank'><li><i><LinkedInIcon/></i></li></a>
              <a href="https://wa.me/919539035376" target='blank'><li><i><WhatsAppIcon/></i></li></a>
              <li><i><TwitterIcon/></i></li>
              <a href='https://www.instagram.com/zlancreations/' target='blank'><li><i><InstagramIcon/></i></li></a>
            </ul>
            </div>
            </div>
          </div>
          <div className='w-1/2'>
            <a href='https://goo.gl/maps/A4zwbo98JYAr5mso8' target='blank'>
            <img src={maps}/>
            </a>
          </div>
        </div>
        </section>
    </ParallaxProvider>
  );
};
