import Manager from "../../svgs/Manager.png";
import Employee1 from "../../svgs/Sathish.png";
import Employee2 from "../../svgs/sreekumar.png";
import Employee3 from "../../svgs/Rahimeen.png";
import Employee4 from "../../svgs/Madhu.png";
import Employee5 from "../../svgs/Lekha.png";
import Employee6 from "../../svgs/athulya.png";
import { SetStateAction, useEffect, useState } from "react";
import Aos from "aos";

export const TeamMobile = () => {
	Aos.init();

	const defaultEmployeeInfo = {
		name: "Madhav Sreekumar",
		role: "CREATIVE DIRECTOR",
	};

	const [managerImage, setManagerImage] = useState(Manager);
	const [employeeInfo, setEmployeeInfo] = useState(defaultEmployeeInfo);
	const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

	const handleEmployeeClick = (
		image: SetStateAction<string>,
		name: string,
		role: string
	) => {
		if (timeoutId !== null) {
			clearTimeout(timeoutId); // Clear the previous timeout
		}
		setManagerImage(image);
		setEmployeeInfo({
			name: name,
			role: role,
		});

		const newTimeoutId = setTimeout(() => {
			setManagerImage(Manager);
			setEmployeeInfo(defaultEmployeeInfo);
		}, 6000);

		setTimeoutId(newTimeoutId); // Save the new timeout ID
	};

	return (
		<section className='mx-10 overflow-hidden' id='team-mobile'>
			<h1
				className='text-white flex justify-center mb-32 w-full text-2xl'
				data-aos='fade-up'
			>
				Our team
			</h1>
			<div className='flex justify-center'>
				<div className='teammobile w-full mb-24'>
					<div className='w-full h-96 relative rounded-2xl'>
						<div className='justify-center flex'>
							<div className='justify-center absolute -top-20'>
								<img
									src={managerImage}
									data-aos='fade-up'
									className='rounded-full border-[20px] w-44 h-44 border-black'
								/>
								<div
									className='flex justify-center items-center flex-col'
									data-aos='fade-up'
								>
									<p className='text-white ml-3 text-center'>
										{employeeInfo.name}
									</p>
									<p className='text-white ml-3 text-center'>
										{employeeInfo.role}
									</p>
								</div>
							</div>
						</div>
						<div className='flex flex-col gap-10'>
							<div className='flex justify-evenly  mt-44 items-center gap-2'>
								<div>
									<img
										src={Employee2}
										className='w-16 h-16 rounded-full'
										onClick={() =>
											handleEmployeeClick(
												Employee2,
												"Sreekumar",
												"Director of Zlan"
											)
										}
										data-aos='fade-up'
									/>
								</div>
								<div>
									<img
										src={Employee3}
										className='w-24 h-24 rounded-full'
										onClick={() =>
											handleEmployeeClick(Employee3, "RAHIMEEN", "PROJECT LEAD")
										}
										data-aos='fade-up'
									/>
								</div>
								<div>
									<img
										src={Employee4}
										className='w-16 h-16 rounded-full'
										onClick={() =>
											handleEmployeeClick(
												Employee4,
												"MADHU",
												"ASSOCIATE CREATIVE DIRECTOR"
											)
										}
										data-aos='fade-up'
									/>
								</div>
							</div>
							<div className='flex justify-evenly items-center'>
								<div>
									<img
										src={Employee1}
										className='w-16 h-16 rounded-full'
										onClick={() =>
											handleEmployeeClick(
												Employee1,
												"Sathish",
												"UI/UX DESIGNER"
											)
										}
										data-aos='fade-up'
									/>
								</div>
								<div>
									<img
										src={Employee6}
										className='w-16 h-16 rounded-full'
										onClick={() =>
											handleEmployeeClick(
												Employee6,
												"ATHULYA",
												"BRAND STRATEGIST"
											)
										}
										data-aos='fade-up'
									/>
								</div>
								<div>
									<img
										src={Employee5}
										className='w-16 h-16 rounded-full'
										onClick={() =>
											handleEmployeeClick(Employee5, "LEKHA", "HR")
										}
										data-aos='fade-up'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
