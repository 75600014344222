import { ServiceButton } from "../Common/services-button";
import Services2 from "../../svgs/Mobile/service-2.png";
import Account from "../../svgs/AccountColored.svg";
import {
	DecorSide,
	RightArrowMobile,
	Servicesline2,
} from "../../svgs/scribble";
import Aos from "aos";

export const ServicesSocialMediaMobile = () => {
	Aos.init();
	return (
		<section className='mx-auto overflow-hidden mb-8 relative'>
			<ServiceButton content={"Services | Dos"} />
			<div className='flex justify-center w-full mt-5 mb-8'>
				<img src={Services2} data-aos='fade-up' />
			</div>
			<div className='flex justify-end mb-10'></div>
			<div className='flex flex-col mx-auto w-full'>
				<div className='flex justify-center flex-col items-center'>
					<div className='text-white text-5xl mx-10'>
						<div className='mb-8'>
							<p className=' mr-auto flex mb-2' data-aos='fade-up'>
								Take Your
							</p>
							<img
								src={Account}
								data-aos='fade-up'
								className='flex flex-grow-0 mb-2'
							/>
							<p data-aos='fade-up'>From Blah to Bliss</p>
						</div>
						<p
							className='text-white font-jost text-sm w-4/5 mb-6'
							data-aos='fade-up'
						>
							Startups, corporates, and entrepreneurs, don't let your social
							media presence go stale. From curating catchy captions to creating
							drool-worthy content, our social savvy experts will take your
							accounts from blah to bliss.
						</p>
						<div data-aos='fade-up'>
							<p className='px-3 py-1 border-white border w-fit text-white text-sm font-poppins  mb-4 rounded-lg'>
								Social Media Strategy & Management
							</p>
						</div>
						<p
							className='text-lg text-white underline underline-offset-4 flex gap-3 items-center'
							data-aos='fade-up'
						>
							Social Media Marketing & SEO{" "}
						</p>
					</div>
				</div>
			</div>
			<div className='absolute bottom-20 -right-16'>
				<DecorSide />
			</div>
		</section>
	);
};
