import { ServiceButton } from "../Common/services-button";
import Services1 from "../../svgs/Mobile/service-1.png";
import ArtisticColored from "../../svgs/ArtisticColored.svg";
import {
	DecorSide,
	RightArrowMobile,
	Servicesline1,
} from "../../svgs/scribble";
import Aos from "aos";
import React from "react";

export const ServicesGraphicMobile = () => {
	Aos.init();
	return (
		<section className='mx-auto overflow-hidden mb-8 relative' id='service'>
			<ServiceButton content={"Services | Uno"} />
			<div className='flex justify-center w-full mt-5 mb-8'>
				<img src={Services1} data-aos='fade-up' />
			</div>
			<div className='flex justify-end mb-10'></div>
			<div className='flex flex-col mx-auto w-full'>
				<div className='flex justify-center flex-col items-center'>
					<div className='text-white text-5xl mx-10'>
						<div className='mb-8'>
							<p className=' mr-auto flex mb-2' data-aos='fade-up'>
								Let Our
							</p>
							<img
								alt=''
								src={ArtisticColored}
								data-aos='fade-up'
								className='flex flex-grow-0 mb-2'
							/>
							<p data-aos='fade-up'>Prowess Run Free</p>
						</div>
						<p className='text-white font-jost text-sm mb-2' data-aos='fade-up'>
							Want to build a brand and give a creative spin?{" "}
						</p>
						<p
							className='text-white font-jost text-sm w-4/5 mb-6'
							data-aos='fade-up'
						>
							We have got you covered. The Zlan clan is here to craft a
							strategic roadmap tailored to your unique vision, delivering
							impactful solutions that amplify your brand's presence and
							captivate your target audience.
						</p>
						<div data-aos='fade-up'>
							<p className='px-3 py-1 border-white border w-fit text-white text-sm font-poppins mb-4 rounded-lg'>
								Brand Strategy / Logo Design / Publishing Design /Animation
							</p>
						</div>
						<p
							className='text-lg text-white underline underline-offset-4 flex gap-3 items-center'
							data-aos='fade-up'
						>
							Graphic Design{" "}
						</p>
					</div>
				</div>
			</div>
			<div className='absolute bottom-20 -right-16'>
				<DecorSide />
			</div>
		</section>
	);
};
