import { ServiceButton } from "../Common/services-button";
import Services3 from "../../svgs/Mobile/service-3.png";
import Interface from "../../svgs/interface.svg";
import {
	DecorSide,
	RightArrowMobile,
	Servicesline3,
} from "../../svgs/scribble";
import Aos from "aos";

export const ServicesWebsiteMobile = () => {
	Aos.init();
	return (
		<section className='mx-auto overflow-hidden mb-8 relative'>
			<ServiceButton content={"Services | Tres"} />
			<div className='flex justify-center w-full mt-5 mb-8'>
				<img src={Services3} data-aos='fade-up' />
			</div>
			{/* <div className="flex justify-end mb-10" >
        <Servicesline3/>
        </div> */}
			<div className='flex flex-col mx-auto w-full'>
				<div className='flex justify-center flex-col items-center'>
					<div className='text-white text-5xl mx-10'>
						<div className='mb-8'>
							<p className=' mr-auto flex mb-2' data-aos='fade-up'>
								Make
							</p>
							<img
								src={Interface}
								data-aos='fade-up'
								className='flex flex-grow-0 mb-2'
							/>
							<p data-aos='fade-up'>Clean and Colorful</p>
						</div>
						<p
							className='text-white font-jost text-sm w-4/5 mb-6'
							data-aos='fade-up'
						>
							“A user interface is like a joke. If you have to explain it, it’s
							not that good” Let us handle your website, app and AI design, so
							you can keep your punchlines fresh.
						</p>
						<div data-aos='fade-up'>
							<p className='px-3 w-fit py-1 border-white border text-white text-sm font-poppins mb-4 rounded-lg'>
								Website / Application / AI Design
							</p>
						</div>
						<p
							className='text-lg text-white underline underline-offset-4 flex gap-3 items-center'
							data-aos='fade-up'
						>
							UX & UI Design
						</p>
					</div>
				</div>
			</div>
			<div className='absolute bottom-20 -right-16'>
				<DecorSide />
			</div>
		</section>
	);
};
