import BGBLUR from "../../svgs/Clients-bg-blur.png";
import Logo1 from "../../svgs/Clients/logo1.png";
import Logo2 from "../../svgs/Clients/logo2.png";
import Logo3 from "../../svgs/Clients/logo3.png";
import Logo4 from "../../svgs/Clients/logo4.png";
import Logo5 from "../../svgs/Clients/logo5.png";
import Logo6 from "../../svgs/Clients/logo6.png";
import Logo7 from "../../svgs/Clients/logo7.png";
import Logo8 from "../../svgs/Clients/logo8.png";
import { DecorSide, Dotsgroup } from "../../svgs/scribble";
import "../../App.css";
import { ParallaxProvider, useParallax } from "react-scroll-parallax";
import Aos from "aos";
import { Decors } from "../HomePage/decors";
import { useState } from "react";

export const Clients = () => {
	const [isLight, setIsLight] = useState(false);
	const HandleLight = () => {
		setIsLight(!isLight);
	};

	const ser1parallax = useParallax<HTMLDivElement>({
		scale: [0.8, 1.2, "easeInQuad"],
		translateY: [0, -0],
		speed: 100,
	});

	Aos.init();
	return (
		<ParallaxProvider>
			<section
				id='client'
				className='h-[750px] overflow-hidden relative'
				onMouseEnter={() => HandleLight()}
				onMouseLeave={() => HandleLight()}
			>
				<div className='inset-0 my-auto h-96 absolute'>
					<h1 className='text-white text-center text-4xl'>Our Pride Clients</h1>
				</div>
				<div className='absolute top-[20vh] left-0 w-10'>
					<Dotsgroup />
				</div>
				<div className='bottom-3 absolute -right-3'>
					<DecorSide />
				</div>
				<div className='flex justify-center items-center h-full container mx-auto'>
					<div className='flex flex-col items-center justify-center'>
						<div
							className='w-1/2 border-white bg-transparent border p-10 mt-24 rounded-3xl absolute z-50'
							data-aos='fade-up'
							ref={ser1parallax.ref}
						>
							<ul className='flex items-center py-2 justify-evenly gap-6'>
								<li>
									<img src={Logo5} />
								</li>
								<li>
									<img src={Logo6} />
								</li>
								<li>
									<img src={Logo2} />
								</li>
								<li>
									<img src={Logo1} />
								</li>
								<li>
									<img src={Logo3} />
								</li>
								<li>
									<img src={Logo4} />
								</li>
								<li>
									<img src={Logo7} />
								</li>
								<li>
									<img src={Logo8} />
								</li>
							</ul>
						</div>
						<img src={BGBLUR} className='w-full' />
					</div>
					<div
						className={`light18 ${
							isLight ? "block" : "hidden"
						} absolute w-4 h-4 z-10 top-[40vh] -left-[5vw]`}
					/>
				</div>
			</section>
		</ParallaxProvider>
	);
};
