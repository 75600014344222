import HandLight from "../../svgs/hand-light.svg";
import {
	Dots,
	Line,
	MobileIcon,
	ScribbleAboutPage,
	SmallLine,
	TabletIcon,
	TvIcon,
} from "../../svgs/scribble";
import { Parallax, ParallaxProvider, useParallax } from "react-scroll-parallax";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useState } from "react";
import { DownloadIcon } from "../Common/services-button";

export const About = () => {
	var settings = {
		dots: false,
		infinite: true,
		arrows: false,
		autoplay: true,
		vertical: true,
		speed: 500,
	};

	const [isLight, setIsLight] = useState(false);
	const HandleLight = () => {
		setIsLight(!isLight);
	};

	const ser1parallax = useParallax<HTMLDivElement>({
		scale: [0.7, 1, "easeInQuad"],
		translateY: [0, 0],
		speed: -100,
	});

	return (
		<>
			<ParallaxProvider>
				<section
					className='h-[100vh] overflow-hidden relative'
					onMouseEnter={() => HandleLight()}
					onMouseLeave={() => HandleLight()}
				>
					<div
						className={`light16 ${
							isLight ? "block" : "hidden"
						} absolute top-[50vh] left-[50vw] z-[9999]`}
					/>
					<div className='absolute top-[10vh] right-0'>
						<ScribbleAboutPage />
					</div>
					<div className='absolute bottom-[0vh] rotate-180 left-0'>
						<ScribbleAboutPage />
					</div>
					<div className='container mx-auto'>
						<div className='flex justify-center items-center h-full flex-col'>
							<i className='mb-3'></i>
							<div data-aos='fade-up' ref={ser1parallax.ref}>
								<img src={HandLight} alt='HandLight' className='h-[70vh]' />
							</div>
							<div className='flex items-center flex-col justify-center w-full mb-4'>
								<Line />
							</div>
							<div className='container'>
								<Slider
									{...settings}
									className='flex justify-center items-center text-center'
								>
									<div data-aos='fade-up'>
										<p className='text-white font-poppins  tracking-widest justify-center text-center font-extralight text-2xl mb-1 flex gap-2'>
											We create lovable{" "}
											<span>
												<i>
													<MobileIcon />
												</i>
											</span>{" "}
											products going
										</p>
										<p className='text-white font-poppins tracking-widest font-extralight text-2xl mb-9'>
											from vision to lunch
										</p>
									</div>
									<div>
										<p className='text-white font-poppins tracking-widest font-extralight justify-center text-center text-2xl mb-1 flex gap-2'>
											We make memorable{" "}
											<span>
												<i>
													<TabletIcon />
												</i>
											</span>{" "}
											sites that
										</p>
										<p className='text-white font-poppins tracking-widest font-extralight text-2xl mb-9'>
											grow your business
										</p>
									</div>
									<div>
										<p className='text-white font-poppins tracking-widest font-extralight justify-center text-center text-2xl mb-1 flex gap-2'>
											We build fun{" "}
											<span>
												<i>
													<TvIcon />
												</i>
											</span>{" "}
											experience using
										</p>
										<p className='text-white font-poppins tracking-widest font-extralight text-2xl mb-9'>
											cutting edge technologies.
										</p>
									</div>
								</Slider>
							</div>
							<a href='/brochure.pdf' target='blank'>
								<button className='download flex gap-3 items-center justify-center px-4 py-2 -mt-1 relative z-20 text-white'>
									<i>
										<DownloadIcon />
									</i>
									<span>Download</span>
								</button>
							</a>
						</div>
					</div>
				</section>
			</ParallaxProvider>
		</>
	);
};
